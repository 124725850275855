import React, { useState } from 'react'

import {
  PrismicLinkType,
  PrismicNavigationGroup,
  PrismicNavigationGroupDataNavigationItems,
  PrismicNavigationItem,
} from 'src/typings/generated/graphql'

import CustomLink from 'src/utils/customLink'

import * as Styles from './footerNavigationGroup.module.scss'

type Props = {
  group: PrismicNavigationGroup
}

const FooterNavigationGroup = ({ group }: Props) => {
  const [open, setOpen] = useState(false)

  const groupName = group.data.group_name
  const navigationItems = group.data
    .navigation_items as PrismicNavigationGroupDataNavigationItems[]

  const navGroupClass = open
    ? `${Styles.navGroup}`
    : `${Styles.navGroup} ${Styles.closed}`

  const renderNavItem = (item: PrismicNavigationItem, i: number) => {
    const link = item.data.link as PrismicLinkType
    const key = `link-${i}`
    return (
      <CustomLink
        key={key}
        link={link}
        className={Styles.navLink}
        data-testid="nav-item"
      >
        {item.data.title?.text ?? ''}
      </CustomLink>
    )
  }

  return (
    <div className={navGroupClass}>
      <h2 className={`${Styles.groupName} ${Styles.groupNameDesktop}`}>
        {groupName}
      </h2>
      <button
        type="button"
        className={`${Styles.groupName} ${Styles.groupNameMobile}`}
        onClick={() => setOpen(!open)}
        aria-expanded={open}
      >
        {groupName}
      </button>
      <div className={Styles.navItems}>
        {navigationItems.map((navItem, i) => {
          const item = navItem.navigation_item
            ?.document as PrismicNavigationItem
          return renderNavItem(item, i)
        })}
      </div>
    </div>
  )
}

export default FooterNavigationGroup
