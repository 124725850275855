import React from 'react'
import { PrismicRichText } from '@prismicio/react'
import BertholdTitle from 'src/components/atoms/bertholdTitle'
import Eyebrow from 'src/images/svgs/Eyebrow.svg'

import QuoteIcon from 'src/images/svgs/icons/QuoteIconDark.svg'
import CustomLink from 'src/utils/customLink'
import { generateRandomId } from 'src/utils/domHelper'
import { PrismicStructuredTextType } from 'src/typings/generated/graphql'
import * as GlobalStyles from 'src/styles/global.module.scss'
import { Link } from 'gatsby'
import * as Styles from './quote.module.scss'

export type QuoteContent = {
  anchor?: string
  ctaText?: string
  description?: PrismicStructuredTextType
  quote?: string
  quoteSignature?: string
  title?: string
}

type Props = {
  content: QuoteContent
}

const Quote = ({ content }: Props) => {
  const {
    anchor = generateRandomId(),
    title = '',
    description,
    quote = '',
    quoteSignature = '',
    ctaText = '',
  } = content
  return (
    <section id={anchor} className={Styles.quote}>
      <div className={Styles.copy}>
        {title && (
          <>
            <div className={Styles.eyebrow}>
              <Eyebrow />
            </div>
            <h2>{title}</h2>
          </>
        )}
        {ctaText && (
          <Link
            className={`${GlobalStyles.redBasicLink} ${Styles.ctaLink}`}
            to="/"
          >
            {ctaText}
          </Link>
        )}
        {description && (
          <PrismicRichText
            field={description?.richText}
            components={{
              hyperlink: ({ node, children }: any) =>
                CustomLink({ link: node.data, children }),
            }}
          />
        )}
      </div>
      <div className={Styles.quoteCopy}>
        <blockquote>
          <div className={Styles.quoteIcon} aria-hidden="true">
            <QuoteIcon aria-label="Quote" />
          </div>
          <p>{BertholdTitle({ text: quote })}</p>
          <p className={Styles.quoteSignature}>{quoteSignature}</p>
        </blockquote>
      </div>
    </section>
  )
}

export default Quote
