import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import Eyebrow from 'src/images/svgs/Eyebrow.svg'
import Checkmark from 'src/images/svgs/icons/Checkmark.svg'
import * as GlobalStyles from 'src/styles/global.module.scss'
import { Link } from 'gatsby'
import * as Styles from './fundraiserCta.module.scss'

const FundraiserCta = ({ content }: any) => {
  const {
    anchor,
    backgroundImage,
    heading,
    primaryCtaText,
    primaryLink,
    primaryLinkTarget,
    secondaryHostLinkText,
    secondaryLink,
    secondaryLinkTarget,
    listItems,
    themeColor,
  } = content

  const fundraiserThemeColors = {
    // prettier-ignore
    'Tan': Styles.tanBackground,
    'Dark Gray 1': Styles.darkGrayBackground,
    'Seafoam 3': Styles.seaFoamBackground,
    'Light Gray 2': Styles.lightGrayBackground,
    // prettier-ignore
    'Gold': Styles.goldBackground,
  }

  const renderThemeClass = () => {
    const themeValue =
      fundraiserThemeColors[themeColor as keyof typeof fundraiserThemeColors]

    return themeValue
  }

  return (
    <section
      id={anchor}
      className={`${Styles.fundraiserContainer} ${renderThemeClass()}`}
    >
      <div className={Styles.infoOuter}>
        <div className={Styles.infoContainer}>
          <Eyebrow className={Styles.eyebrow} />
          <h2>{heading}</h2>
          <ul className={Styles.fundraiserList}>
            {listItems?.map((item: string) => (
              <li key={item}>
                <Checkmark className={Styles.checkMark} /> <span>{item}</span>
              </li>
            ))}
          </ul>
          <div className={Styles.buttonContainer}>
            {primaryCtaText && (
              <Link
                to={primaryLink}
                target={primaryLinkTarget}
                className={GlobalStyles.redButton}
              >
                {primaryCtaText}
              </Link>
            )}
            {secondaryLink && (
              <Link
                to={secondaryLink}
                target={secondaryLinkTarget}
                className={`${GlobalStyles.redBasicLink}`}
              >
                {secondaryHostLinkText}
              </Link>
            )}
          </div>
        </div>
      </div>
      {backgroundImage && (
        <GatsbyImage
          className={Styles.fundraiserImage}
          image={backgroundImage}
          alt="fundraiser"
        />
      )}
    </section>
  )
}

export default FundraiserCta
