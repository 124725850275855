import { graphql } from 'gatsby'
import React from 'react'
import LinkGrid from 'src/components/organisms/slidersAndGrids/linkGrid'

type Props = {
  slice: any
}

type LinkGridContent = {
  anchor?: string
  heading?: string
  links?: any[]
}

const LinkGridSlice = ({ slice }: Props) => {
  const content: LinkGridContent = {
    anchor: slice.primary.anchor ?? '',
    heading: slice.primary.heading?.text ?? '',
    links: slice.items.map((item: any) => ({
      link_text: item.link_text ?? '',
      link_url: item.link?.url ?? '',
    })),
  }

  return <LinkGrid content={content} />
}

export default LinkGridSlice

export const linkGridFragment = graphql`
  fragment PageDataBodyLinkGrid on PrismicPageDataBodyLinkGrid {
    primary {
      anchor
      heading {
        text
      }
    }
    items {
      link_text
      link {
        url
        target
      }
    }
  }
`
