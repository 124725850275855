import React from 'react'
import { graphql } from 'gatsby'
import TitleAndDescription, {
  TitleAndDescriptionContent,
} from 'src/components/organisms/general/titleAndDescription'
import { PrismicPageDataBodyTitleAndDescription } from 'src/typings/generated/graphql'

type Props = {
  slice: PrismicPageDataBodyTitleAndDescription
}

const TitleAndDescriptionSlice = ({ slice }: Props) => {
  const content: TitleAndDescriptionContent = {
    anchor: slice.primary.anchor ?? undefined,
    eyebrowText: slice.primary.eyebrow_text ?? undefined,
    description: slice.primary?.description ?? undefined,
    title: slice.primary.title ?? undefined,
    textAlign: slice.primary.title_text_align ?? undefined,
    titleAsH1: slice.primary.title_as_h1 ?? undefined,
    backgroundColor: slice.primary.background_color ?? undefined,
    hideEyebrow: slice.primary.hide_eyebrow ?? undefined,
    hasTrademark: slice.primary.has_trademark ?? undefined,
    themeColor: slice.primary.theme_color ?? undefined,
  }
  return <TitleAndDescription content={content} />
}

export default TitleAndDescriptionSlice

export const TitleAndDescriptionFragment = graphql`
  fragment PageDataBodyTitleAndDescription on PrismicPageDataBodyTitleAndDescription {
    primary {
      anchor
      description {
        richText
      }
      title
      title_text_align
      title_as_h1
      eyebrow_text
      background_color
      hide_eyebrow
      has_trademark
      theme_color
    }
  }
`
