import React from 'react'
import Eyebrow from 'src/images/svgs/Eyebrow.svg'
import * as Styles from './linkGrid.module.scss'

export type GridContent = {
  anchor?: string
  heading?: string
  links?: any[]
}

type Props = {
  content: GridContent
}

type Link = {
  link_text: string
  link_url: string
}

const LinkGrid = ({ content }: Props) => {
  const { anchor, heading = '', links } = content

  return (
    <section id={anchor} className={Styles.linkGrid}>
      <div>{heading && <h2>{heading}</h2>}</div>
      <div className={Styles.linksOuter}>
        {links &&
          links?.map((link: Link) => (
            <a
              className={Styles.linkContainer}
              key={link.link_text}
              href={link.link_url}
              aria-label={`link to the ${link.link_text}`}
            >
              <Eyebrow className={Styles.eyebrow} />
              <span className={Styles.linkText}>{link.link_text}</span>
              <span className={Styles.linkArrow}>
                <span />
              </span>
            </a>
          ))}
      </div>
    </section>
  )
}

export default LinkGrid
