import { IGatsbyImageData } from 'gatsby-plugin-image'
import React from 'react'
import { PrismicLinkType } from 'src/typings/generated/graphql'
import * as GlobalStyles from 'src/styles/global.module.scss'
import CustomLink from 'src/utils/customLink'
import BertholdTitle from 'src/components/atoms/bertholdTitle'
import { generateRandomId } from 'src/utils/domHelper'
import * as Styles from './callToAction.module.scss'

enum CallToActionContentLayout {
  center = 'center',
  left = 'left',
}

enum CallToActionBackgroundOverlay {
  light = 'light',
  dark = 'dark',
}

export type CallToActionContent = {
  anchor?: string
  headline?: string
  description?: string
  buttonLink?: PrismicLinkType
  buttonText?: string
  contentLayout?: string
  backgroundImage?: IGatsbyImageData
  backgroundImageAlt?: string
  backgroundImageUrl?: string
  backgroundOverlay?: string
}

type Props = {
  content: CallToActionContent
}

const CallToAction = ({ content }: Props) => {
  const {
    anchor = generateRandomId(),
    headline = '',
    description = '',
    buttonText = '',
    buttonLink,
    contentLayout = CallToActionContentLayout.center,
    backgroundImageUrl,
    backgroundOverlay = CallToActionBackgroundOverlay.light,
  } = content

  const contentLayoutFormatted = contentLayout.toLowerCase()
  const backgroundOverlayFormatted = backgroundOverlay.toLowerCase()
  let textContainerClass = Styles.ctaTextContainer
  let buttonClass = GlobalStyles.redGhostButtonResponsive

  if (backgroundOverlayFormatted === CallToActionBackgroundOverlay.dark) {
    textContainerClass += ` ${Styles.darkOverlay}`
    buttonClass = GlobalStyles.whiteGhostButtonResponsive
  }

  if (contentLayoutFormatted === CallToActionContentLayout.left)
    textContainerClass += ` ${Styles.leftAligned}`

  return (
    <section
      id={anchor}
      className={Styles.callToAction}
      style={{
        backgroundImage: backgroundImageUrl ? `url(${backgroundImageUrl})` : '',
      }}
    >
      <div className={textContainerClass}>
        <div
          data-testid="cta-headline"
          className={Styles.headline}
          role="heading"
          aria-level={2}
        >
          {BertholdTitle({ text: headline })}
        </div>
        <div data-testid="cta-description" className={Styles.description}>
          {description}
        </div>
        {buttonLink?.url && (
          <div data-testid="cta-button-container" className={Styles.button}>
            <CustomLink className={buttonClass} link={buttonLink}>
              {buttonText}
            </CustomLink>
          </div>
        )}
      </div>
    </section>
  )
}

export default CallToAction
