import React, { useEffect, useRef } from 'react'
import { generateRandomId } from 'src/utils/domHelper'
import * as Styles from './embeddedContent.module.scss'

enum EmbeddedContentType {
  webpage,
  pdf,
  video,
  flipsnack,
}

export type EmbeddedContentContent = {
  anchor?: string
  url: string
  title: string
}

type Props = {
  content: EmbeddedContentContent
}

const EmbeddedContent = ({ content }: Props) => {
  const { url, anchor = generateRandomId(), title } = content

  if (!url) return null

  let wrapperClass = Styles.contentWrapper
  let embededType = EmbeddedContentType.webpage

  if (url.endsWith('.pdf')) {
    embededType = EmbeddedContentType.pdf
  } else if (url.indexOf('flipsnack') > -1) {
    wrapperClass += ` ${Styles.flipsnack}`
    embededType = EmbeddedContentType.flipsnack
  } else if (
    url.indexOf('youtube.com/embed/') > -1 ||
    url.indexOf('youtu.be') > -1
  ) {
    wrapperClass += ` ${Styles.video}`
    embededType = EmbeddedContentType.video
  }

  // iframe management
  const iframeRef = useRef<HTMLIFrameElement>(null)

  useEffect(() => {
    const iframe = iframeRef.current

    const handleIframeMessage = (e: MessageEvent) => {
      if (
        e.source === iframe?.contentWindow &&
        e.data.type === 'formHeightChanged'
      ) {
        const newHeight = e.data.height
        iframe.style.height = `${newHeight}px`
      }
    }

    window.addEventListener('message', handleIframeMessage)

    return () => {
      window.removeEventListener('message', handleIframeMessage)
    }
  }, [])

  // Render

  return (
    <section id={anchor} className={Styles.embeddedContent}>
      <div className={wrapperClass}>
        {embededType === EmbeddedContentType.webpage && (
          <iframe ref={iframeRef} title={title} src={url} />
        )}
        {embededType === EmbeddedContentType.pdf && (
          <iframe
            scrolling="no"
            title={title}
            src={`https://docs.google.com/viewerng/viewer?url=${url}&embedded=true#toolbar=0&view=FitH`}
          />
        )}
        {embededType === EmbeddedContentType.flipsnack && (
          <iframe
            scrolling="no"
            title={title}
            src={`${url}#toolbar=0&view=FitH`}
          />
        )}
        {embededType === EmbeddedContentType.video && (
          <iframe
            scrolling="no"
            title={title}
            src={`${url}#toolbar=0&view=FitH`}
            data-src={`${url}#toolbar=0&view=FitH`}
            className="optanon-category-0005"
          />
        )}
      </div>
    </section>
  )
}

export default EmbeddedContent
