import { graphql } from 'gatsby'
import React from 'react'
import FundraiserCta from 'src/components/organisms/ctas/fundraiserCta'

type Props = {
  slice: any
}

const HostFundraiserSlice = ({ slice }: Props) => {
  const content: any = {
    anchor: slice.primary.anchor ?? undefined,
    backgroundImage:
      slice.primary.background_image?.gatsbyImageData ?? undefined,
    heading: slice.primary.heading?.text ?? undefined,
    primaryCtaText: slice.primary.primary_cta_text?.text ?? undefined,
    primaryLink: slice.primary.primary_link.url ?? undefined,
    primaryLinkTarget: slice.primary.primary_link?.target ?? undefined,
    secondaryHostLinkText:
      slice.primary.secondary_host_link_text?.text ?? undefined,
    secondaryLink: slice.primary.secondary_link.url ?? undefined,
    secondaryLinkTarget: slice.primary.secondary_link?.target ?? undefined,
    listItems: slice.items.map((item: any) => item.list_item.text),
    themeColor: slice.primary.theme_color ?? undefined,
  }
  return <FundraiserCta content={content} />
}

export default HostFundraiserSlice

export const hostFundraiserFragment = graphql`
  fragment PageDataBodyHostAFundraiser on PrismicPageDataBodyHostAFundraiser {
    items {
      list_item {
        text
      }
    }
    primary {
      anchor
      background_image {
        gatsbyImageData
        url
      }
      heading {
        text
      }
      primary_cta_text {
        text
      }
      primary_link {
        url
        target
      }
      secondary_host_link_text: secondary_link_text {
        text
      }
      secondary_link {
        url
        target
      }
      theme_color
    }
  }
`
