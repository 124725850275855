import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import BertholdTitle from 'src/components/atoms/bertholdTitle'

import { customScrollTo, generateRandomId } from 'src/utils/domHelper'
import * as Styles from './secondaryNavigation.module.scss'

export type SecondaryNavigationItem = {
  anchor?: string
  navigationText?: string
}

export type SecondaryNavigationContent = {
  items?: SecondaryNavigationItem[]
}

type Props = {
  content: SecondaryNavigationContent
}

const SecondaryNavigation = ({ content }: Props) => {
  const ref = useRef() as MutableRefObject<HTMLElement>
  const { items = [] } = content

  const [activeItem, setActiveItem] = useState(items[0].anchor ?? '')
  const [sliderItem, setSliderItem] = useState(0)
  const [totalNavItems, setTotalNavItems] = useState(0)
  const [navWidth, setNavWidth] = useState(0)
  const [previousBtnDisabled, setPreviousBtnDisabled] = useState(false)
  const [nextBtnDisabled, setNextBtnDisabled] = useState(false)
  const [windowWidth, setWindowWidth] = useState(0)
  const [showNavButtons, setShowNavButtons] = useState(true)

  // determines which nav item should be set to active based on scroll position
  const detectTopSlice = () => {
    const boundingRect = ref.current.getBoundingClientRect()
    const yTarget = boundingRect.y + boundingRect.height + 1
    const topSlice = document
      .elementsFromPoint(window.innerWidth / 2, yTarget)
      .find(el => el.tagName.match(/section/gi)) as Element
    if (topSlice && topSlice.id) setActiveItem(topSlice.id)
  }

  const calculateNav = () => {
    const windowInnerWidth = window.innerWidth
    const navItems = document.querySelectorAll('.secondary-nav-item')
    const navContainer = document.getElementById(
      'nav-container-inner'
    ) as HTMLElement

    setWindowWidth(windowInnerWidth)
    setTotalNavItems(navItems.length)
    setNavWidth(navContainer.offsetWidth)

    let itemsWidth: number = 140 // 140 is the initial items outer padding

    Array.from(navItems).every((item: any, i: number) => {
      const htmlElement = item as HTMLElement
      const elementWidth = htmlElement.offsetWidth
      let marginValue: number

      if (i === 0 || i + 1 === navItems.length) {
        marginValue = 65
      } else {
        marginValue = 80
      }

      itemsWidth += elementWidth + marginValue

      if (itemsWidth > windowInnerWidth) {
        setSliderItem(i)
        return false
      }

      return true
    })

    setShowNavButtons(windowInnerWidth < navContainer.offsetWidth)
  }

  const shouldShowNavButtons = () => {
    const windowInnerWidth = window.innerWidth
    const navContainer = document.getElementById(
      'nav-container-inner'
    ) as HTMLElement
    const navigationWidth = navContainer.offsetWidth

    setShowNavButtons(windowInnerWidth < navigationWidth)
  }

  useEffect(() => {
    calculateNav()
    detectTopSlice()
    window.addEventListener('scroll', detectTopSlice, true)
    window.addEventListener('resize', shouldShowNavButtons)

    return () => {
      window.removeEventListener('scroll', detectTopSlice, true)
    }
  }, [items])

  const handleNextItem = () => {
    // If the screen is large enough. We just move the slider to the end.
    // If it is a mobile view, we slide through each item.
    const isFullScreen = windowWidth < navWidth * 2 && windowWidth > 480
    const sliderItemValue = sliderItem === -1 ? 2 : 1
    const currentItem = document.querySelector(
      `#nav-item-${sliderItem}`
    ) as HTMLElement
    const nextItem = document.querySelector(
      `#nav-item-${
        isFullScreen ? totalNavItems - 1 : sliderItem + sliderItemValue
      }`
    ) as HTMLElement

    if (currentItem !== null) {
      nextItem?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center',
      })
    }

    if (nextItem !== null) {
      setSliderItem(sliderItem + 1)
    } else {
      setNextBtnDisabled(true)
    }

    setPreviousBtnDisabled(false)
  }

  const handlePrevItem = () => {
    // If the screen is large enough. We just move the slider to the end.
    // If it is a mobile view, we slide through each item.
    const isFullScreen = windowWidth < navWidth * 2 && windowWidth > 480
    const sliderItemValue = sliderItem === 2 ? 2 : 1

    if (sliderItem === 1) {
      setPreviousBtnDisabled(true)
    }

    const nextItem = document.querySelector(
      `#nav-item-${isFullScreen ? 0 : sliderItem - sliderItemValue}`
    ) as HTMLElement

    nextItem?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center',
    })

    setSliderItem(sliderItem - 1)

    setNextBtnDisabled(false)
  }

  return (
    <div className={Styles.navContainer}>
      {showNavButtons && (
        <button
          className={Styles.prevButton}
          type="button"
          aria-label="Previous menu item"
          onClick={handlePrevItem}
          disabled={previousBtnDisabled}
        />
      )}
      <section className={Styles.secondaryNavigation} ref={ref}>
        <div className={Styles.navItems} id="nav-container-inner">
          {items.map((item, i: number) => {
            const { anchor = generateRandomId(), navigationText = '' } = item
            let className = Styles.navItem
            if (activeItem === anchor) className += ` ${Styles.active}`
            const key = `secondary-nav-${i}`

            const navigationHandler = () => {
              customScrollTo(`#${item.anchor}`, 'smooth')
              const targetItem = document.getElementById(`${item.anchor}`)
              targetItem?.focus()
              setTimeout(() => setActiveItem(anchor), 500)
            }

            return (
              <div
                className={`${className} secondary-nav-item`}
                key={key}
                id={`nav-item-${i}`}
              >
                <div
                  onClick={navigationHandler}
                  onKeyDown={e => {
                    if (e.code === 'Enter') {
                      navigationHandler()
                    }
                  }}
                  role="link"
                  tabIndex={0}
                >
                  {BertholdTitle({ text: navigationText })}
                </div>
              </div>
            )
          })}
        </div>
      </section>
      {showNavButtons && (
        <button
          className={Styles.nextButton}
          type="button"
          aria-label="Next menu item"
          onClick={handleNextItem}
          disabled={nextBtnDisabled}
        />
      )}
    </div>
  )
}
export default SecondaryNavigation
