import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { customScrollTo, generateRandomId } from 'src/utils/domHelper'
import BertholdTitle from 'src/components/atoms/bertholdTitle'

import {
  Maybe,
  PrismicArticle,
  PrismicCategory,
  PrismicLinkType,
} from 'src/typings/generated/graphql'

import { useQueryParamString } from 'react-use-query-param-string'

import * as GlobalStyles from 'src/styles/global.module.scss'

import CustomLink from 'src/utils/customLink'
import Eyebrow from 'src/images/svgs/Eyebrow.svg'
import CategoryPill, { IPillStyle } from 'src/components/atoms/categoryPill'
import ArticleCard from 'src/components/molecules/cards/articleCard'
import RightArrowIcon from 'src/images/svgs/icons/RightArrowIcon.svg'
import { articleBannerImage } from 'src/utils/articlesHelper'
import ScreenReaderOnlyText from 'src/components/atoms/screenReaderOnlyText'
import * as Styles from './articlesLibrary.module.scss'

export type ArticlesLibraryContent = {
  anchor?: string
  categoriesHeader?: string
  placeholderCardLink?: PrismicLinkType
  placeholderCardLinkText?: string
}

type Props = {
  content: ArticlesLibraryContent
}

function sanitizeId(id: string) {
  return id.toLowerCase().replaceAll(' ', '-').replaceAll("'", '')
}

const ArticlesLibrary = ({ content }: Props) => {
  const {
    anchor = generateRandomId(),
    categoriesHeader = '',
    placeholderCardLink,
    placeholderCardLinkText = '',
  } = content
  const [pageQuery, setPageQuery] = useQueryParamString('page', '1')
  const [categoryQuery, setCategoryQuery] = useQueryParamString('category', '')

  const currentPage = Number.isNaN(pageQuery) ? 1 : Number(pageQuery)
  const activeCategory = categoryQuery.toLowerCase()

  const displayPlaceholderCard = placeholderCardLink?.url
  const articlesPerPage = displayPlaceholderCard ? 5 : 6

  /**
   * Content Preparation
   */

  const articlesQuery = useStaticQuery(graphql`
    query ArticlesLibrary {
      allPrismicCategory(sort: { data: { name: ASC } }) {
        nodes {
          data {
            name
          }
        }
      }
      allPrismicArticle(
        sort: [{ data: { post_date: DESC } }, { first_publication_date: DESC }]
      ) {
        nodes {
          uid
          first_publication_date
          data {
            featured
            title {
              text
            }
            url {
              url
            }
            banner_image {
              gatsbyImageData
              alt
            }
            thumbnail_image {
              gatsbyImageData
              alt
            }
            category {
              document {
                ...CategoryFragment
              }
            }
            post_date
          }
        }
      }
    }
  `)

  const allCategories = articlesQuery.allPrismicCategory
    .nodes as PrismicCategory[]
  const allArticles = articlesQuery.allPrismicArticle.nodes as PrismicArticle[]

  const allArticlesCategorized = allArticles.reduce<{
    [index: string]: PrismicArticle[]
  }>((prev, cur) => {
    let key = ''
    const articleCategory = cur.data?.category
      ?.document as Maybe<PrismicCategory>
    if (articleCategory) key = articleCategory.data.name?.toLowerCase() ?? ''
    return { ...prev, [key]: [...(prev[key] || []), cur] }
  }, {})

  const allActiveArticles =
    activeCategory && activeCategory in allArticlesCategorized
      ? allArticlesCategorized[activeCategory] ?? []
      : allArticles

  let featuredArticleIndex = allActiveArticles.findIndex(
    article => article.data.featured === true
  )
  if (featuredArticleIndex === -1) featuredArticleIndex = 0
  const featuredArticle = allActiveArticles[featuredArticleIndex]
  const activeArticles = allActiveArticles.filter(
    (_, i) => i !== featuredArticleIndex
  )

  const activeArticlesPaged = [[]] as PrismicArticle[][]
  for (let i = 0; i < activeArticles.length; i += articlesPerPage) {
    activeArticlesPaged[Math.floor(i / articlesPerPage)] = activeArticles.slice(
      i,
      i + articlesPerPage
    )
  }

  /**
   * Actions
   */

  const setPage = (i: number) => {
    setPageQuery(`${i}`)
  }

  /**
   * Renderings
   */

  const featuredArticleEyebrow = () => {
    const category = featuredArticle.data.category
      ?.document as Maybe<PrismicCategory>
    if (category)
      return (
        <div className={Styles.pillEyebrow}>
          <CategoryPill category={category.data.name ?? ''} />
        </div>
      )

    return (
      <div className={Styles.eyebrow}>
        <Eyebrow />
      </div>
    )
  }

  const pager = () => {
    let offset = 1
    if (activeArticlesPaged.length > articlesPerPage) {
      if (currentPage > 3) offset = currentPage - 2
      if (currentPage >= activeArticlesPaged.length - 1)
        offset = activeArticlesPaged.length - 4
    }

    const prevClass =
      currentPage === 1
        ? `${Styles.pagerButton} ${Styles.prev} ${Styles.hidden}`
        : `${Styles.pagerButton} ${Styles.prev}`
    const nextClass =
      currentPage === activeArticlesPaged.length
        ? `${Styles.pagerButton} ${Styles.next} ${Styles.hidden}`
        : `${Styles.pagerButton} ${Styles.next}`

    const maxPageButtons = 5
    const pagerLength =
      activeArticlesPaged.length > articlesPerPage
        ? offset + maxPageButtons - 1
        : activeArticlesPaged.length
    const pages = []
    for (let i = offset; i <= pagerLength; i += 1) {
      let pageClass = Styles.pageButton
      if (i === currentPage) pageClass += ` ${Styles.currentPage}`
      pages.push(
        <button
          type="button"
          className={pageClass}
          onClick={() => setPage(i)}
          key={`pager-${i}`}
          aria-current={currentPage === i ? 'page' : false}
        >
          {`0${i}`.slice(-2)}
        </button>
      )
    }

    return (
      <nav className={Styles.pager} role="navigation" aria-label="pagination">
        <ul>
          <li>
            <button
              type="button"
              className={prevClass}
              onClick={() => {
                setPage(currentPage !== 1 ? currentPage - 1 : 1)
                customScrollTo(`#articles-${anchor}`)
              }}
            >
              Prev
              <ScreenReaderOnlyText>previous page</ScreenReaderOnlyText>
            </button>
          </li>
          {pages.map(page => (
            <li key={page.key}>
              {page}
              <ScreenReaderOnlyText>{`page ${page.props.children}`}</ScreenReaderOnlyText>
            </li>
          ))}
          <li>
            <button
              type="button"
              className={nextClass}
              onClick={() => {
                setPage(
                  currentPage !== activeArticlesPaged.length
                    ? currentPage + 1
                    : activeArticlesPaged.length
                )
                customScrollTo(`#articles-${anchor}`)
              }}
            >
              Next
              <ScreenReaderOnlyText>next page</ScreenReaderOnlyText>
            </button>
          </li>
        </ul>
      </nav>
    )
  }

  return (
    <section id={anchor} className={Styles.articlesLibrary}>
      <div className={Styles.categoriesContainer}>
        <h3 id="articles-library-header" aria-level={2}>
          {BertholdTitle({ text: categoriesHeader })}
        </h3>
        <div
          className={Styles.categories}
          role="tablist"
          aria-labelledby="articles-library-header"
        >
          <button
            id="tab-button-all"
            role="tab"
            type="button"
            className={Styles.category}
            key="category-all"
            onClick={() => {
              const newCategory = ''
              setCategoryQuery(newCategory.toLowerCase())
              setPage(1)
            }}
            aria-label="All"
            aria-controls="tab-all"
            aria-selected={!activeCategory}
          >
            <CategoryPill
              category="All"
              style={!activeCategory ? 'grey-red' : 'basic'}
              link={false}
            />
          </button>
          {allCategories.map((category, i) => {
            const categoryName = category.data.name ?? ''

            if (!allArticlesCategorized[categoryName.toLowerCase()]) return null

            const style =
              categoryName.toLowerCase() === activeCategory
                ? 'grey-red'
                : 'basic'
            const key = `category-${i}`
            return (
              <button
                id={sanitizeId(`tab-button-${categoryName}`)}
                role="tab"
                type="button"
                className={Styles.category}
                key={key}
                onClick={() => {
                  const newCategory =
                    activeCategory === categoryName.toLowerCase()
                      ? ''
                      : categoryName
                  setCategoryQuery(newCategory.toLowerCase())
                  setPage(1)
                }}
                aria-label={categoryName}
                aria-controls={sanitizeId(`tab-${categoryName}`)}
                aria-selected={activeCategory === categoryName.toLowerCase()}
              >
                <CategoryPill
                  category={categoryName}
                  style={style as IPillStyle}
                  link={false}
                />
              </button>
            )
          })}
        </div>
      </div>
      <div
        id={activeCategory ? sanitizeId(`tab-${activeCategory}`) : 'tab-all'}
        role="tabpanel"
        aria-labelledby={
          activeCategory
            ? sanitizeId(`tab-button-${activeCategory}`)
            : 'tab-button-all'
        }
      >
        <div className={Styles.featuredArticle}>
          <div className={Styles.imageWrapper}>
            {articleBannerImage(featuredArticle)}
          </div>
          <div className={Styles.details}>
            {featuredArticleEyebrow()}
            <h2 aria-level={3}>
              {featuredArticle.data.title?.text &&
                BertholdTitle({ text: featuredArticle.data.title?.text })}
            </h2>
            <a
              href={
                featuredArticle.data.url?.url ?? `/news/${featuredArticle.uid}`
              }
              target={featuredArticle.data.url?.url ? '_blank' : '_self'}
              className={GlobalStyles.redBasicLink}
              rel="noreferrer"
            >
              Read More
              <ScreenReaderOnlyText>read more</ScreenReaderOnlyText>
            </a>
          </div>
        </div>
        <div id={`articles-${anchor}`} className={Styles.articles}>
          {activeArticlesPaged[currentPage - 1] &&
            activeArticlesPaged[currentPage - 1].map((article, i) => {
              const key = `activeArticlesPage-${i}`
              return (
                <div className={Styles.article} key={key}>
                  <ArticleCard article={article} />
                </div>
              )
            })}
          {displayPlaceholderCard && (
            <div
              className={`${Styles.article} ${Styles.placeholderCardContainer}`}
            >
              <CustomLink
                link={placeholderCardLink}
                className={Styles.placeholderCard}
              >
                <div>
                  {placeholderCardLinkText}
                  <div className={Styles.arrow}>
                    <RightArrowIcon />
                  </div>
                </div>
              </CustomLink>
            </div>
          )}
        </div>
        {pager()}
      </div>
    </section>
  )
}

export default ArticlesLibrary
