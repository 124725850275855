import { graphql } from 'gatsby'
import React from 'react'
import { PrismicPageDataBodyCallToAction } from 'src/typings/generated/graphql'
import CallToAction, {
  CallToActionContent,
} from 'src/components/organisms/ctas/callToAction'

interface Props {
  slice: PrismicPageDataBodyCallToAction
}

const CallToActionSlice = ({ slice }: Props) => {
  const content: CallToActionContent = {
    anchor: slice.primary.anchor ?? undefined,
    headline: slice.primary.headline ?? undefined,
    description: slice.primary.description_text ?? undefined,
    buttonLink: slice.primary.button_link ?? undefined,
    buttonText: slice.primary.button_text ?? undefined,
    contentLayout: slice.primary.content_layout ?? undefined,
    backgroundImage:
      slice.primary.background_image?.gatsbyImageData ?? undefined,
    backgroundImageAlt: slice.primary.background_image?.alt ?? undefined,
    backgroundImageUrl: slice.primary.background_image?.url ?? undefined,
    backgroundOverlay: slice.primary.background_overlay ?? undefined,
  }

  return <CallToAction content={content} />
}

export default CallToActionSlice

export const callToActionFragment = graphql`
  fragment PageDataBodyCallToAction on PrismicPageDataBodyCallToAction {
    primary {
      anchor
      content_layout
      button_link {
        ...BasicLinkFragment
      }
      button_text
      description_text
      headline
      background_overlay
      background_image {
        alt
        gatsbyImageData
        url
      }
    }
  }
`
