// extracted by mini-css-extract-plugin
export var buttonContainer = "fundraiserCta-module--button-container--e0db0";
export var checkMark = "fundraiserCta-module--check-mark--c536a";
export var darkGrayBackground = "fundraiserCta-module--darkGrayBackground--5ea88";
export var eyebrow = "fundraiserCta-module--eyebrow--1b1d9";
export var fundraiserContainer = "fundraiserCta-module--fundraiser-container--c4284";
export var fundraiserImage = "fundraiserCta-module--fundraiser-image--2eb8c";
export var fundraiserList = "fundraiserCta-module--fundraiser-list--24fb6";
export var goldBackground = "fundraiserCta-module--goldBackground--31ba6";
export var infoContainer = "fundraiserCta-module--info-container--7c6a3";
export var infoOuter = "fundraiserCta-module--info-outer--bdd6d";
export var lightGrayBackground = "fundraiserCta-module--lightGrayBackground--18360";
export var seaFoamBackground = "fundraiserCta-module--seaFoamBackground--0fac8";
export var tanBackground = "fundraiserCta-module--tanBackground--91f93";