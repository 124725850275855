import React from 'react'
import { graphql } from 'gatsby'
import Table, { TableContent } from 'src/components/organisms/general/table'

type Props = {
  slice: any
}
const TableSlice = ({ slice }: Props) => {
  const content: TableContent = {
    tableHeader1: slice.primary.table_header_1 ?? undefined,
    tableHeader2: slice.primary.table_header_2 ?? undefined,
    tableHeader3: slice.primary.table_header_3 ?? undefined,
    tableHeader4: slice.primary.table_header_4 ?? undefined,
    tableHeader5: slice.primary.table_header_5 ?? undefined,
    tableRows: slice.items,
  }

  return <Table content={content} />
}

export default TableSlice

export const TableFragment = graphql`
  fragment PageDataBodyTable on PrismicPageDataBodyTable {
    slice_label
    primary {
      table_header_1
      table_header_2
      table_header_3
      table_header_4
      table_header_5
    }
    items {
      table_column_1
      table_column_2
      table_column_3
      table_column_4
      table_column_5
    }
  }
`
