import React, { useState } from 'react'
import { navigate } from 'gatsby'
import { PrismicLinkType } from 'src/typings/generated/graphql'
import BackwardCarrotIcon from 'src/images/svgs/icons/BackwardCarrotIcon.svg'
import ForwardCarrotIcon from 'src/images/svgs/icons/ForwardCarrotIcon.svg'
import * as GlobalStyles from 'src/styles/global.module.scss'
import CustomLink from 'src/utils/customLink'
import * as SwipeHandler from 'src/utils/swipeHandler'
import BertholdTitle from 'src/components/atoms/bertholdTitle'
import SaucyCarouselItem, {
  SaucyCarouselItemContent,
} from 'src/components/molecules/saucyCarouselItem'
import { customLinkResolver } from 'src/utils/linkResolver'
import { generateRandomId } from 'src/utils/domHelper'
import * as Styles from './saucyCarousel.module.scss'

export type SaucyCarouselContent = {
  anchor?: string
  title?: string
  ctaLinkText?: string
  ctaLink?: PrismicLinkType
  items: SaucyCarouselItemContent[]
}

type Props = {
  content: SaucyCarouselContent
}

const SaucyCarousel = ({ content }: Props) => {
  const [activeItem, setActiveItem] = useState(0)
  const [gestureStart, setGestureStart] = useState(0)
  const [gestureEnd, setGestureEnd] = useState(0)

  const {
    anchor = generateRandomId(),
    title = '',
    ctaLinkText = '',
    ctaLink,
    items = [],
  } = content

  const getSlideWrapperClassName = (index: number) => {
    if (
      index === activeItem - 2 ||
      (activeItem === 0 && index === items.length - 2) ||
      (activeItem === 1 && index === items.length - 1)
    )
      return `${Styles.slideWrapper} ${Styles.prev2}`
    if (
      index === activeItem - 1 ||
      (activeItem === 0 && index === items.length - 1)
    )
      return `${Styles.slideWrapper} ${Styles.prev}`
    if (index === activeItem) return `${Styles.slideWrapper} ${Styles.active}`
    if (
      index === activeItem + 1 ||
      (activeItem === items.length - 1 && index === 0)
    )
      return `${Styles.slideWrapper} ${Styles.next}`
    if (
      index === activeItem + 2 ||
      (activeItem === items.length - 1 && index === 1) ||
      (activeItem === items.length - 2 && index === 0)
    )
      return `${Styles.slideWrapper} ${Styles.next2}`

    return `${Styles.slideWrapper} ${Styles.hidden}`
  }

  const handleSlideSelector = (
    slideIndex: number,
    isActive: boolean,
    link: PrismicLinkType | undefined
  ) => {
    if (!isActive) {
      setActiveItem(slideIndex)
      return
    }

    if (link) {
      const customLink = customLinkResolver(link)
      navigate(customLink.linkPath)
    }
  }

  const generateSlides = () => {
    const slides: any[] = []
    items.forEach((item, i) => {
      const key = `saucy-carousel-item-${i}`
      const isActive = activeItem === i
      const slideWrapperTabIndex = isActive ? 0 : -1
      const slideWrapperProps = item.link
        ? {
            role: item.link ? 'button' : undefined,
            tabIndex: isActive ? 0 : -1,
            onClick: () => handleSlideSelector(i, isActive, item.link),
            onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => {
              if (e.key === 'Enter') handleSlideSelector(i, isActive, item.link)
            },
          }
        : { tabIndex: slideWrapperTabIndex }
      slides.push(
        <div
          className={getSlideWrapperClassName(i)}
          key={key}
          aria-label={item?.title}
          {...slideWrapperProps}
        >
          <SaucyCarouselItem content={item} active={isActive} />
        </div>
      )
    })
    return slides
  }

  const back = () => {
    const newActive = activeItem - 1
    setActiveItem(newActive < 0 ? items.length - 1 : newActive)
  }

  const next = () => {
    const newActive = activeItem + 1
    setActiveItem(newActive % items.length)
  }

  return (
    <section id={anchor} className={Styles.saucyCarousel}>
      <div
        data-testid="sc-title"
        className={`${Styles.title} ${Styles.selectDisable}`}
        aria-hidden="true"
      >
        <h2>{BertholdTitle({ text: title })}</h2>
      </div>
      {ctaLink?.url && (
        <div data-testid="sc-cta" className={Styles.actionContainer}>
          <CustomLink link={ctaLink} className={GlobalStyles.redBasicLink}>
            {ctaLinkText}
          </CustomLink>
        </div>
      )}
      <div
        data-testid="sc-carousel"
        className={Styles.carouselContainer}
        aria-label={title}
      >
        <button
          className={`${Styles.arrow} ${Styles.arrowLeft}`}
          type="button"
          onClick={back}
          aria-label="Show previous slider item"
        >
          <BackwardCarrotIcon />
        </button>
        <div
          className={Styles.carouselWrapper}
          role="region"
          aria-label="slideshow"
          onTouchStart={touchStartEvent => {
            SwipeHandler.touchStart(touchStartEvent, setGestureStart)
            setGestureEnd(touchStartEvent.targetTouches[0].clientX)
          }}
          onTouchMove={touchMoveEvent => {
            SwipeHandler.touchMove(touchMoveEvent, setGestureEnd)
          }}
          onTouchEnd={() => {
            SwipeHandler.swipeEnd(gestureStart, gestureEnd, back, next)
          }}
        >
          <div className={Styles.carousel}>{generateSlides()}</div>
        </div>
        <button
          className={`${Styles.arrow} ${Styles.arrowRight}`}
          type="button"
          onClick={next}
          aria-label="Show next slider item"
        >
          <ForwardCarrotIcon />
        </button>
      </div>
      {ctaLink?.url && (
        <div
          data-testid="sc-web-cta"
          className={`${Styles.actionContainer} ${Styles.web}`}
        >
          <CustomLink link={ctaLink} className={GlobalStyles.redBasicLink}>
            {ctaLinkText}
          </CustomLink>
        </div>
      )}
    </section>
  )
}

export default SaucyCarousel
