import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React from 'react'
import { PrismicRichText } from '@prismicio/react'
import BertholdTitle from 'src/components/atoms/bertholdTitle'

import {
  Maybe,
  PrismicArticle,
  PrismicCategory,
  PrismicLinkType,
  PrismicStructuredTextType,
} from 'src/typings/generated/graphql'

import * as GlobalStyles from 'src/styles/global.module.scss'

import Eyebrow from 'src/images/svgs/Eyebrow.svg'
import CustomLink from 'src/utils/customLink'
import CategoryPill from 'src/components/atoms/categoryPill'
import { generateRandomId } from 'src/utils/domHelper'
import * as Styles from './dynamicCta.module.scss'

enum DynamicCtaLinkStyle {
  button = 'button',
  link = 'link',
}

enum DynamicCtaImageLayout {
  left = 'left',
  right = 'right',
}

enum DynamicCtaImageSize {
  large = 'large',
  medium = 'medium',
  small = 'small',
}

export type DynamicCtaContent = {
  anchor?: string
  eyebrowText?: string
  headline?: string
  description?: PrismicStructuredTextType
  link?: PrismicLinkType
  linkText?: string
  linkStyle?: string
  image?: IGatsbyImageData
  imageAlt?: string
  imageLayout?: string
  imageSize?: string
  mobileOnly?: boolean
  themeColor?: string
}

type Props = {
  content: DynamicCtaContent
}

const DynamicCta = ({ content }: Props) => {
  const {
    anchor = generateRandomId(),
    eyebrowText = '',
    headline = '',
    description,
    link,
    linkText = '',
    linkStyle = DynamicCtaLinkStyle.button,
    image,
    imageAlt = '',
    imageLayout = DynamicCtaImageLayout.right,
    imageSize = DynamicCtaImageSize.large,
    mobileOnly = false,
    themeColor = 'white with black text',
  } = content

  const dynamicCtaThemeColor = {
    'white with black text': Styles.whiteWithBlackText,
    'black with white text': Styles.blackWithWhiteText,
    'tan with black text': Styles.tanWithBlackText,
    'seafoam 3 with black text': Styles.seafoam3WithBlackText,
    'light gray 2 with black text': Styles.lightGray2WithBlackText,
    'gold with black text': Styles.goldWithBlackText,
  }

  const linkStyleFormatted = linkStyle.toLowerCase()
  const imageLayoutFormatted = imageLayout.toLowerCase()
  const imageSizeFormatted = imageSize.toLowerCase()
  let layoutClass = ''
  if (imageLayoutFormatted === DynamicCtaImageLayout.left)
    layoutClass = Styles.imageLeft
  if (imageSizeFormatted === DynamicCtaImageSize.small)
    layoutClass += ` ${Styles.wideContent}`

  let imageSizeClass = Styles.imageLarge
  if (imageSizeFormatted === DynamicCtaImageSize.medium)
    imageSizeClass = Styles.imageMedium
  else if (imageSizeFormatted === DynamicCtaImageSize.small)
    imageSizeClass = Styles.imageSmall

  // Render
  const renderThemeClass = () => {
    const themeColorValue =
      dynamicCtaThemeColor[themeColor as keyof typeof dynamicCtaThemeColor]

    return themeColorValue
  }

  const renderEyebrow = () => {
    const linkDocumentType = link?.document?.type

    if (!headline) return null

    // If link is to Article, set category as eyebrow
    if (linkDocumentType === 'article') {
      const article = link?.document as PrismicArticle
      const category = article.data?.category
        ?.document as Maybe<PrismicCategory>
      if (category)
        return (
          <div className={Styles.pillEyebrow}>
            <CategoryPill category={category.data.name ?? ''} />
          </div>
        )
    }

    if (eyebrowText) return <p className={Styles.textEyebrow}>{eyebrowText}</p>

    return (
      <div className={Styles.eyebrow}>
        <Eyebrow />
      </div>
    )
  }

  const renderLink = () => {
    if (!link?.url) return null

    const tempLink = link as PrismicLinkType

    const basicLinkType =
      themeColor === 'black with white text'
        ? GlobalStyles.whiteBasicLink
        : GlobalStyles.redBasicLink
    const responsiveLinkType =
      themeColor === 'black with white text'
        ? GlobalStyles.whiteButtonResponsive
        : GlobalStyles.redButtonResponsive

    const styleClass =
      linkStyleFormatted === DynamicCtaLinkStyle.link
        ? basicLinkType
        : responsiveLinkType

    return (
      <CustomLink link={tempLink} className={styleClass}>
        {linkText ?? ''}
      </CustomLink>
    )
  }

  let sliceClass = `${Styles.copyImageCtaSlice} ${layoutClass}`
  if (mobileOnly) {
    sliceClass += ` ${Styles.mobileOnly}`
  }

  return (
    <section id={anchor} className={`${sliceClass} ${renderThemeClass()}`}>
      {!!image && (
        <div className={`${Styles.imageWrapper} ${imageSizeClass}`}>
          <GatsbyImage
            image={image}
            alt={imageAlt ?? ''}
            className={Styles.image}
          />
        </div>
      )}
      <div className={Styles.contentWrapper}>
        <div className={Styles.content}>
          {renderEyebrow()}
          <h2>{BertholdTitle({ text: headline })}</h2>
          <PrismicRichText
            field={description?.richText}
            components={{
              hyperlink: ({ node, children }: any) =>
                CustomLink({ link: node.data, children }),
            }}
          />
          <div className={Styles.link}>{renderLink()}</div>
        </div>
      </div>
    </section>
  )
}

export default DynamicCta
