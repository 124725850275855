import React, { useEffect, useState } from 'react'
import {
  PrismicMenuItem,
  PrismicLinkType,
  Maybe,
} from 'src/typings/generated/graphql'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import BasicLinkCard from 'src/components/molecules/cards/basicLinkCard'

import { generateRandomId } from 'src/utils/domHelper'

import * as Styles from './basicSlider.module.scss'

export type BasicSliderItem = {
  title?: string
  image?: IGatsbyImageData
  imageAlt?: string
  link?: PrismicLinkType
  linkText?: string
  overrideTitleWithLink?: boolean
  overrideImageWithLink?: boolean
}

export type BasicSliderContent = {
  anchor?: string
  items: BasicSliderItem[]
}

type Props = {
  content: BasicSliderContent
}

const BasicSlider = ({ content }: Props) => {
  const { anchor = generateRandomId(), items } = content
  const [visibleItems, setVisibleItems] = useState(0)
  const [activeItem, setActiveItem] = useState(0)

  const calculateSliderOffset = () => {
    const windowWidth = window.innerWidth
    const menuItem = document.querySelector('#basic-slider-0') as HTMLElement
    if (menuItem) {
      const menuItemWidth = menuItem.offsetWidth + 25
      let visibleMenuItems = windowWidth / menuItemWidth
      visibleMenuItems = Math.trunc(visibleMenuItems)

      setVisibleItems(visibleMenuItems)
      setActiveItem(visibleMenuItems)
    }
  }

  useEffect(() => {
    calculateSliderOffset()
  }, [items])

  const generateSlides = () =>
    items.map((item, i: number) => {
      let { title = '', image, imageAlt = '' } = item
      const {
        link,
        linkText = '',
        overrideTitleWithLink = false,
        overrideImageWithLink = false,
      } = item

      // cast the link in case title or image is overridden
      const menuItem: Maybe<PrismicMenuItem> =
        link && link.type === 'menu_item'
          ? (link.document as PrismicMenuItem)
          : null

      if (overrideTitleWithLink) {
        if (menuItem) title = menuItem.data.name ?? title
      }

      if (overrideImageWithLink) {
        if (menuItem) {
          image = menuItem.data.image?.gatsbyImageData
          imageAlt = menuItem.data.image?.alt ?? imageAlt
        }
      }

      const key = `basic-slider-${i}`

      return (
        <div className={Styles.slide} key={key} id={key}>
          <BasicLinkCard
            title={title}
            image={image}
            imageAlt={imageAlt}
            link={link}
            linkText={linkText}
          />
        </div>
      )
    })

  const handleNextItem = () => {
    const currentItem = document.querySelector(
      `#basic-slider-${activeItem + 1}`
    ) as HTMLElement

    currentItem?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    })

    if (currentItem !== null) {
      setActiveItem(activeItem + 1)
    }
  }

  const handlePrevItem = () => {
    const currentItem = document.querySelector(
      `#basic-slider-${activeItem - 1}`
    ) as HTMLElement

    currentItem?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'end',
    })

    if (visibleItems < activeItem) {
      setActiveItem(activeItem - 1)
    }
  }

  return (
    <section id={anchor} className={Styles.basicSlider}>
      <button
        type="button"
        onClick={handlePrevItem}
        className={Styles.prevButton}
        aria-label="Previous item"
      />
      <button
        type="button"
        onClick={handleNextItem}
        className={Styles.nextButton}
        aria-label="Next item"
      />
      <div
        id={`slider-wrapper-${anchor}`}
        className={`${Styles.sliderWrapper} basic-slider-wrapper`}
      >
        {generateSlides()}
      </div>
    </section>
  )
}

export default BasicSlider
