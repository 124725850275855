import React from 'react'

import * as Styles from './categoryPill.module.scss'

import ScreenReaderOnlyText from './screenReaderOnlyText'

export type IPillStyle = 'basic' | 'grey-red' | 'grey-gold'

type Props = {
  category: string
  style?: IPillStyle
  link?: boolean
  tabIndex?: number
}

const CategoryPill = ({
  category,
  style = 'basic',
  link = true,
  tabIndex,
}: Props) => {
  let styleClass = Styles.categoryPill
  if (style === 'grey-red') styleClass += ` ${Styles.greyRed}`
  else if (style === 'grey-gold') styleClass += ` ${Styles.greyGold}`

  return (
    <div className={styleClass}>
      {link && (
        <a
          className={Styles.category}
          href={`/news?category=${category.toLowerCase()}`}
          onClick={e => {
            e.stopPropagation()
          }}
          tabIndex={tabIndex}
        >
          <ScreenReaderOnlyText>
            {`${category} Articles -- View all Articles`}
          </ScreenReaderOnlyText>
          {category}
        </a>
      )}
      {!link && <div className={Styles.category}>{category}</div>}
    </div>
  )
}

export default CategoryPill
