import React from 'react'
import * as Styles from './table.module.scss'

export type TableContent = {
  tableHeader1?: string
  tableHeader2?: string
  tableHeader3?: string
  tableHeader4?: string
  tableHeader5?: string
  tableRows?: any[]
}

type Props = {
  content: TableContent
}

const Table = ({ content }: Props) => {
  const {
    tableHeader1 = '',
    tableHeader2 = '',
    tableHeader3 = '',
    tableHeader4 = '',
    tableHeader5 = '',
    tableRows = [],
  } = content

  return (
    <div className={Styles.tableOuter}>
      <table className={Styles.table}>
        <tr>
          <th>{tableHeader1}</th>
          <th>{tableHeader2}</th>
          <th>{tableHeader3}</th>
          <th>{tableHeader4}</th>
          <th>{tableHeader5}</th>
        </tr>
        {tableRows?.map((tableRow: any) => (
          <tr>
            {Object.keys(tableRow).map(key => (
              <td>{tableRow[key]}</td>
            ))}
          </tr>
        ))}
      </table>
    </div>
  )
}

export default Table
