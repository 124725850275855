import { graphql } from 'gatsby'
import * as React from 'react'
import { VerticalImageReelImages } from 'src/components/molecules/verticalImageReel'
import ImageReel, {
  ImageReelContent,
} from 'src/components/organisms/ctas/imageReel'
import { PrismicPageDataBodyImageReel } from 'src/typings/generated/graphql'

type Props = {
  slice: PrismicPageDataBodyImageReel
}

const ImageReelSlice = ({ slice }: Props) => {
  const content: ImageReelContent = {
    anchor: slice.primary.anchor ?? undefined,
    eyebrowText: slice.primary.eyebrow_text ?? undefined,
    headline: slice.primary.headline ?? undefined,
    description: slice.primary.description ?? undefined,
    button1Link: slice.primary.button_1_link ?? undefined,
    button1Text: slice.primary.button_1_text ?? undefined,
    button2Link: slice.primary.button_2_link ?? undefined,
    button2Text: slice.primary.button_2_text ?? undefined,
    button3Link: slice.primary.button_3_link ?? undefined,
    button3Text: slice.primary.button_3_text ?? undefined,
    backgroundStyle: slice.primary.background_style ?? undefined,
    showRetroIcons: slice.primary.show_retro_icons ?? undefined,
    showSocialMedia: slice.primary.show_social_media ?? undefined,
    items: slice.items.map(
      item =>
        ({
          image: item.image?.gatsbyImageData ?? undefined,
          imageAlt: item.image?.alt ?? undefined,
        } as VerticalImageReelImages)
    ),
  }

  return <ImageReel content={content} />
}

export default ImageReelSlice

export const imageReelFragment = graphql`
  fragment PageDataBodyImageReel on PrismicPageDataBodyImageReel {
    items {
      image {
        alt
        gatsbyImageData(layout: CONSTRAINED, width: 350)
      }
    }
    primary {
      anchor
      background_style
      show_retro_icons
      show_social_media
      description {
        richText
      }
      eyebrow_text
      headline
      social_media_hashtag
      button_1_link {
        url
      }
      button_1_text
      button_2_link {
        url
      }
      button_2_text
      button_3_link {
        url
      }
      button_3_text
    }
  }
`
