// extracted by mini-css-extract-plugin
export var blackWithWhiteText = "dynamicCta-module--blackWithWhiteText--1d0c0";
export var content = "dynamicCta-module--content--82809";
export var contentWrapper = "dynamicCta-module--content-wrapper--29f69";
export var copyImageCtaSlice = "dynamicCta-module--copy-image-cta-slice--8d3dc";
export var eyebrow = "dynamicCta-module--eyebrow--afee8";
export var goldWithBlackText = "dynamicCta-module--goldWithBlackText--a2cf7";
export var image = "dynamicCta-module--image--be028";
export var imageLarge = "dynamicCta-module--image-large--62698";
export var imageLeft = "dynamicCta-module--image-left--fa344";
export var imageMedium = "dynamicCta-module--image-medium--c72f8";
export var imageSmall = "dynamicCta-module--image-small--210c6";
export var imageWrapper = "dynamicCta-module--image-wrapper--c4af2";
export var lightGray2WithBlackText = "dynamicCta-module--lightGray2WithBlackText--38d3f";
export var link = "dynamicCta-module--link--4045c";
export var mobileOnly = "dynamicCta-module--mobile-only--707aa";
export var pillEyebrow = "dynamicCta-module--pill-eyebrow--c3c85";
export var seafoam3WithBlackText = "dynamicCta-module--seafoam3WithBlackText--683c8";
export var tanWithBlackText = "dynamicCta-module--tanWithBlackText--811ce";
export var textEyebrow = "dynamicCta-module--text-eyebrow--079b2";
export var whiteWithBlackText = "dynamicCta-module--whiteWithBlackText--2b5fe";
export var wideContent = "dynamicCta-module--wide-content--e7274";