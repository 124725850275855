// extracted by mini-css-extract-plugin
export var blackWithWhiteText = "titleAndDescription-module--blackWithWhiteText--ebe63";
export var bodyContainer = "titleAndDescription-module--body-container--0cd4a";
export var centerContent = "titleAndDescription-module--center-content--53d36";
export var eyebrow = "titleAndDescription-module--eyebrow--95456";
export var goldWithBlackText = "titleAndDescription-module--goldWithBlackText--5c08f";
export var noDescription = "titleAndDescription-module--no-description--63124";
export var purple3WithWhiteText = "titleAndDescription-module--purple3WithWhiteText--d518b";
export var redWithWhiteText = "titleAndDescription-module--redWithWhiteText--27253";
export var seafoam3WithWhiteText = "titleAndDescription-module--seafoam3WithWhiteText--df37c";
export var title = "titleAndDescription-module--title--9f8bc";
export var titleAndDescription = "titleAndDescription-module--title-and-description--5e651";
export var titleContainer = "titleAndDescription-module--title-container--bd67c";
export var titleCopy = "titleAndDescription-module--title-copy--f8fbb";
export var whiteWithBlackText = "titleAndDescription-module--whiteWithBlackText--d532c";